import defaultState from './state'
import Vue from 'vue'

export function resetState(state) {
  Object.assign(state, defaultState)
}

export function query(state, query) {
  state.query = query
}

export function limit(state, limit) {
  state.limit = parseInt(limit)
}

export function start(state, start) {
  state.start = parseInt(start)
}

export function addFilters(state, filters) {
  let merged = [...state.filters, ...filters]
  let deduped = [...new Map(merged.map(item => [item['term'], item])).values()]
  state.filters = deduped
}

export function removeFilter(state, target) {
  if (state.filters.length === 1) {
    // Then it's that one we're removing
    state.filters = []
  } else {
    let prunedArray = state.filters.filter(item => {
      return (item.type !== target.type || item.term !== target.term || item.mode !== target.mode)
    })
    state.filters = prunedArray
  }
}

export function emptyFilters(state) {
  state.filters = []
}

export function setSortPreferenceNewestFirst(state) {
  state.sortPreference = 'desc'
}

export function setSortPreferenceOldestFirst(state) {
  state.sortPreference = 'asc'
}

export function resetSortPreference(state) {
  state.sortPreference = 'relevance'
}

export function setSortPreference(state, choice) {
  state.sortPreference = choice
}

export function addUserToWatchlist(state, username) {
  Vue.set(state.watchlist, username, true)
  localStorage.setItem('watchlist', JSON.stringify(state.watchlist))
}

export function removeUserFromWatchlist(state, username) {
  Vue.delete(state.watchlist, username)
  localStorage.setItem('watchlist', JSON.stringify(state.watchlist))
}

export function addSiteToWatchlist(state, siteName) {
  Vue.set(state.site_watchlist, siteName, true)
  localStorage.setItem('site_watchlist', JSON.stringify(state.site_watchlist))
}

export function removeSiteWatchlist(state, siteName) {
  Vue.delete(state.site_watchlist, siteName)
  localStorage.setItem('site_watchlist', JSON.stringify(state.site_watchlist))
}

export function addForumToWatchlist(state, forum) {
  Vue.set(state.forum_watchlist, forum, true)
  localStorage.setItem('forum_watchlist', JSON.stringify(state.forum_watchlist))
}

export function removeForumWatchlist(state, forum) {
  Vue.delete(state.forum_watchlist, forum)
  localStorage.setItem('forum_watchlist', JSON.stringify(state.forum_watchlist))
}

export function setWatchlist(state, watchlist) {
  state.watchlist = watchlist
}

export function setSiteWatchlist(state, siteWatchlist) {
  state.site_watchlist = siteWatchlist
}

export function setForumWatchlist(state, forumWatchlist) {
  state.forum_watchlist = forumWatchlist
}

export function addTopicToWatchlist(state, topic) {
  Vue.set(state.topic_watchlist, topic, true)
  localStorage.setItem('topic_watchlist', JSON.stringify(state.topic_watchlist))
}

export function removeTopicFromWatchlist(state, username) {
  Vue.delete(state.topic_watchlist, username)
  localStorage.setItem('topic_watchlist', JSON.stringify(state.topic_watchlist))
}

export function setTopicWatchlist(state, topicWatchlist) {
  state.topic_watchlist = topicWatchlist
}
