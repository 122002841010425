<template>
  <div class="filter-container">
    <div
      class="forum-search__active-filter-info"
      :class="{ 'included-filter': filter.mode === '+', 'excluded-filter': filter.mode === '-' }"
    >
      <template v-if="filter.type === 'bookmarked'">
        + Bookmarked:
        <b class="u-break">{{ filter.term }}</b>
      </template>
      <template v-else>
        {{ filter.mode }} {{ filterTypes[filter.type] }}:
        <b class="u-break">{{ filter.type === 'posted_date' ? readableDate(filter.term) : filter.term }}</b>
      </template>
    </div>
    <button
      :data-mode="filter.mode"
      :data-type="filter.type"
      :data-term="filter.term"
      :aria-label="gettext('Remove filter')"
      type="button"
      class="btn btn--light forum-search__remove-filter"
      @click.prevent="$emit('removeFilter', $event)"
    >
      <svg
        aria-hidden="true"
        focusable="false"
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="14"
        viewBox="0 0 100 100"
      >
        <path d="M90 20L80 10 50 40 20 10 10 20l30 30-30 30 10 10 30-30 30 30 10-10-30-30z" />
      </svg>
    </button>
  </div>
</template>

<script>
export default {
  name: 'ActiveFilter',
  props: {
    filter: {
      type: Object,
      default: () => {}
    },
    filterTypes: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style scoped>
.filter-container {
  display: flex;
  gap: 0.35em;
}
.included-filter {
  border: 4px solid #9ebfa3;
}
.excluded-filter {
  border: 4px solid #bf9ea7;
}
</style>
