<template>
  <form
    method="GET"
    class="forum-search"
  >
    <div class="form-group">
      <label for="search_forums">
        {{ gettext('Search messages:') }}
      </label>
      <div class="input-btn-group search-with-btn">
        <input
          id="search_forums"
          v-model="query"
          type="search"
          autocomplete="nope"
          aria-describedby="search_forums-desc"
        >
        <button
          class="btn btn--primary btn--inline"
          @click.prevent="searchMessages"
        >
          <img
            src="/static/images/icons/icon-search-white.svg"
            width="24"
            alt="Search"
            style="display:block"
          >
        </button>
        <div class="message__details-wrapper">
          <details
            id="search_forums-desc"
            class="message__details u-px-md"
          >
            <summary>How to combine search terms</summary>
            <ul class="u-mb-xs">
              <li>
                Include more than one term in the results by separating them with <code>+</code>, such as <code>Canada + Mexico</code>
              </li>
              <li>
                Include one or more terms by separating them with <code>|</code>, such as <code>Canada | Mexico</code>
              </li>
              <li>
                Perform a complex search by grouping terms and modifiers with parentheses, such as <code>(Canada + Mexico) | Netherlands</code>
              </li>
            </ul>
          </details>
        </div>
      </div>
    </div>

    <div class="forum-search__filter-select">
      <div class="forum-search__filter-type">
        <label for="filter_by">
          {{ gettext('Filter by:') }}
        </label>
        <div class="input-group">
          <select
            id="filter_by"
            v-model="filterBy"
          >
            <option value="">
              &nbsp;
            </option>
            <option
              v-for="(text, val) in filterTypes"
              :key="val"
              :value="val"
            >
              {{ text }}
            </option>
          </select>
        </div>
      </div>
      <div class="forum-search__filter-term">
        <template v-if="filterBy === 'posted_date'">
          <label for="filter_term">
            {{ gettext('Select date:') }}
          </label>
          <div class="input-group">
            <input
              id="filter_term"
              v-model="filterTerm"
              type="date"
              autocomplete="nope"
              @keyup.enter="addFilterAndReset({ mode: filterMode, type: filterBy, term: filterTerm })"
            >
          </div>
        </template>
        <template v-else>
          <label for="filter_term">
            {{ gettext('Filter term:') }}
          </label>
          <div class="input-group">
            <input
              id="filter_term"
              v-model="filterTerm"
              type="text"
              autocomplete="nope"
              @keyup.enter="addFilterAndReset({ mode: filterMode, type: filterBy, term: filterTerm })"
            >
          </div>
        </template>
      </div>
      <div class="forum-search__filter-include">
        <div class="input-group">
          <select
            id="filter_mode"
            v-model="filterMode"
          >
            <option value="+">
              include
            </option>
            <option value="-">
              exclude
            </option>
          </select>
        </div>
      </div>
      <div class="forum-search__add-filter">
        <button
          type="button"
          class="btn btn--sm btn--dark"
          @click.prevent="addFilter({ mode: filterMode, type: filterBy, term: filterTerm })"
        >
          {{ pgettext('add filter', 'Add') }}
        </button>
      </div>
    </div>
    <div class="bookmark-filter">
      <label for="bookmark-filter">Filter by Bookmark:</label>
      <select
        id="bookmark-filter"
        v-model="bookmarkFilterType"
      >
        <option
          v-for="(value, key) in bookmarkFilters"
          :key="key"
          :value="key"
        >
          {{ value }}
        </option>
      </select>
    </div>
    <div
      v-if="initDocId || filters && filters.length > 0"
      class="forum-search__active-filters"
    >
      <div class="forum-search__active-filters-head">
        <p>
          {{ gettext('Active filters') }}
        </p>
        <button
          type="button"
          class="btn btn--xs btn--light forum-search__remove-all-filters"
          @click.prevent="removeAllFilters"
        >
          {{ gettext('Remove all') }}
        </button>
      </div>

      <div
        v-if="initDocId"
        class="forum-search__active-filter"
      >
        <div class="forum-search__active-filter-info">
          Doc id:
          <b>{{ initDocId }}</b>
        </div>
        <button
          :aria-label="gettext('Remove doc_id')"
          type="button"
          class="btn btn--light forum-search__remove-filter"
          @click.prevent="$emit('removeDocId', true)"
        >
          <svg
            aria-hidden="true"
            focusable="false"
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 100 100"
          >
            <path d="M90 20L80 10 50 40 20 10 10 20l30 30-30 30 10 10 30-30 30 30 10-10-30-30z" />
          </svg>
        </button>
      </div>

      <p
        v-if="showIncludedFilters"
        class="filter-header"
      >
        Included
      </p>
      <div
        v-for="(filter, index) in includedFilters"
        :key="'include' + index"
        class="forum-search__active-filter"
      >
        <active-filter
          :filter="filter"
          :filterTypes="filterTypes"
          @removeFilter="removeFilter"
        />
      </div>
      <p
        v-if="showExcludedFilters"
        class="filter-header"
      >
        Excluded
      </p>
      <div
        v-for="(filter, index) in excludedFilters"
        :key="'exclude' + index"
        class="forum-search__active-filter"
      >
        <active-filter
          :filter="filter"
          :filterTypes="filterTypes"
          @removeFilter="removeFilter"
        />
      </div>
    </div>
  </form>
</template>

<script>
import ActiveFilter from './ActiveFilter'
export default {
  name: 'MessageSearchForm',

  components: {
    ActiveFilter
  },

  props: {
    initQuery: {
      type: String,
      default: ''
    },
    initFilters: {
      type: String,
      default: ''
    },
    initDocId: {
      type: String,
      default: ''
    },
    filterTypes: {
      type: Object,
      required: true
    },
  },

  data() {
    return {
      filterMode: '+',
      filterBy: '',
      filterTerm: '',
      bookmarkFilterType: '',
      bookmarkFilters: {
        all: 'All Bookmarks',
        chat: 'Chat Bookmarks',
        forum: 'Forum Bookmarks',
        none: 'All Messages'
      },
      showIncludedFilters: false,
      showExcludedFilters: false
    }
  },

  computed: {
    query: {
      get() {
        return this.$store.state.query
      },
      set(query) {
        this.$store.commit('query', query)
        return query
      },
    },
    filters: {
      get() {
        return this.$store.state.filters
      },
      set(filters) {
        this.$store.commit('addFilters', filters)
        return filters
      }
    },
    termObj() {
      return {
        type: this.filterBy,
        term: this.filterTerm,
      }
    },
    includedFilters() {
      return this.filters.filter(item => item.mode === '+')
    },
    excludedFilters() {
      return this.filters.filter(item => item.mode === '-')
    }
  },
  watch: {
    bookmarkFilterType(option) {
      if (option === 'none' || option === '') {
        this.resetBookmarkFilter()
      } else {
        this.bookmarkFilter(option)
      }
    },
    filters() {
      this.filterModeCheck()
    }
  },
  mounted() {
    this.filterModeCheck()
  },

  methods: {
    readableDate(timestamp) {
      let date = new Date(timestamp * 1000)
      return date.toUTCString()
    },

    addFilterAndReset(filter) {
      this.addFilter(filter)
      document.getElementById('filter_term').blur()
      document.getElementById('filter_by').focus()
    },
    addFilter(filter) {
      if (filter.type === 'bookmarked') {
        const bookmarkIndex = this.filters.findIndex(
          (filter) => filter.type === 'bookmarked'
        )
        if (bookmarkIndex !== -1) {
          this.filters.splice(bookmarkIndex, 1, filter)
        } else {
          this.filters.push(filter)
        }
        this.filterTerm = filter
      } else {
        this.filters = this.filters.filter((filter) => filter.type !== 'bookmarked') // filter out filter.type == bookmarked
        this.filters.push(filter)
      }
      if (this.filterBy === 'posted_date') { // filterBy refers to the input role's "Filter by" input field
        let dateString = filter.term
        let date = Date.parse(dateString) / 1000
        filter.term = date
      }
      if (filter.type && filter.term) {
        this.$store.commit('addFilters', [filter])
        this.filterMode = '+'
        this.filterBy = ''
        this.filterTerm = ''
      }
    },
    bookmarkFilter() {
      this.filterBy = 'bookmarked'
      this.addFilter({type: this.filterBy, term: this.bookmarkFilterType})
    },
    resetBookmarkFilter() {
      const bookmarkIndex = this.filters.findIndex((filter) => filter.type === 'bookmarked')
      if (bookmarkIndex !== -1) {
        this.filters.splice(bookmarkIndex, 1)
      }
      this.filterBy = ''
      this.filterTerm = ''
    },
    removeFilter(e) {
      let targetMode = e.target.getAttribute('data-mode')
      let targetType = e.target.getAttribute('data-type')
      let targetTerm = e.target.getAttribute('data-term')
      this.$store.commit('removeFilter', {mode: targetMode, type: targetType, term: targetTerm})
      if (targetType === 'bookmarked') {
        this.resetBookmarkFilter()
        this.bookmarkFilterType = ''
      }
    },
    removeAllFilters() {
      this.$emit('removeDocId')
      this.$store.commit('emptyFilters')
      this.bookmarkFilterType = ''
    },
    searchMessages() {
      this.$emit('manualMessageSearchStart')
    },
    filterModeCheck() {
      this.showIncludedFilters = false
      this.showExcludedFilters = false
      this.filters.forEach(filter => {
        if (filter.mode === '+') this.showIncludedFilters = true
        if (filter.mode === '-') this.showExcludedFilters = true
      })
    }
  },
}
</script>
<style>
.bookmark-filter {
  width: 28.5%;
}
.search-with-btn button {
  vertical-align: top;
  padding-top: 0.4em;
  padding-bottom: 0.4em;
  border: 2px solid #c22c3f;
}
#search_forums,
.message__details-wrapper {
  width: calc(100% - 78px);
}
.message__details {
  cursor: pointer;
}
#filter_mode {
  width: 145px;
  min-width: 145px;
}
.filter-header {
  margin-bottom: 4px;
  padding-top: 10px;
  border-top: 1px solid #fff;
}
.filter-header:last-of-type {
  margin-top: 20px;
}
</style>
